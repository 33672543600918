// frontend/src/components/Cart.js
import React from 'react';
import PropTypes from 'prop-types';
import './Cart.css';
import { useNavigate, Link } from 'react-router-dom';

function Cart({ cartItems, setCartItems, products }) {
  const navigate = useNavigate();
  const [shippingCost, setShippingCost] = React.useState(0);
  const TAX_RATE = 0.095;

  // find the product data by name
  const getProductDetails = (name) => {
    if (!products || !Array.isArray(products)) {
      console.error("Products data is undefined or not an array.");
      return null;
    }
    return products.find((product) => product.name === name);
  };

  // remove from cart
  const handleDeleteItem = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCart);
  };

  // compute subtotal
  const subtotal = cartItems.reduce((total, item) => {
    const product = getProductDetails(item.name);
    return total + ((product?.price || 0) * item.quantity);
  }, 0);

  React.useEffect(() => {
    setShippingCost(10);
  }, []);

  const tax = subtotal * TAX_RATE;
  const total = subtotal + tax + shippingCost;

  // create checkout session
  const handleStripeCheckout = async () => {
    if (!cartItems || cartItems.length === 0) {
      alert("Your cart is empty. Please add items before checkout.");
      return;
    }

    try {
      // build cart items array for Stripe
      const cartItemsForStripe = cartItems.map((item) => {
        const product = getProductDetails(item.name);
        return {
          name: item.name,
          size: item.size,                    // pass size
          imageUrls: product?.imageUrls || [],// pass images
          quantity: item.quantity,
          unitPrice: product?.price || 0,
        };
      });

      const response = await fetch("https://api.nebstudios.com/api/create-checkout-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cartItems: cartItemsForStripe }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        console.error("No Stripe session URL returned.");
      }
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  return (
    <div className="cart-page">
      {cartItems.length > 0 && (
        <button className="close-cart" onClick={() => navigate('/')}>
          &times;
        </button>
      )}

      <div className="cart-left">
        {cartItems.length === 0 ? (
          <p className="empty-cart-message">
            Empty Cart.{' '}
            <span>
              Go <Link to="/" className="empty-cart-link">Shop</Link>.
            </span>
          </p>
        ) : (
          <ul className="cart-items">
            {cartItems.map((item, index) => {
              const product = getProductDetails(item.name);
              if (!product) {
                console.warn(`Product details not found for: ${item.name}`);
                return (
                  <li key={index} className="cart-item">
                    <p>Product details not found.</p>
                  </li>
                );
              }

              return (
                <li key={index} className="cart-item">
                  <div className="image-container">
                    <img
                      src={product.imageUrls?.[0] || 'https://via.placeholder.com/100'}
                      alt={item.name}
                      className="cart-item-image"
                      onError={(e) => {
                        e.target.src = 'https://via.placeholder.com/100';
                      }}
                    />
                  </div>

                  <div className="cart-item-details">
                    <div className="product-name-price-container">
                      <div className="product-info">
                        <span className="product-title">{item.name}</span>
                        <span className="product-price">
                          ${product.price.toFixed(2)}
                        </span>
                      </div>
                      <button
                        className="delete-item-button"
                        onClick={() => handleDeleteItem(index)}
                        aria-label={`Delete ${item.name}`}
                      >
                        &times;
                      </button>
                    </div>
                    <div className="product-size">
                      Size: {item.size}
                    </div>
                    <div className="product-quantity">
                      Quantity: {item.quantity}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {cartItems.length > 0 && (
        <div className="cart-right">
          <h2>Order Summary</h2>
          <table className="summary-table">
            <tbody>
              <tr>
                <td>Subtotal:</td>
                <td>${subtotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Tax:</td>
                <td>${tax.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Shipping:</td>
                <td>${shippingCost.toFixed(2)}</td>
              </tr>
              <tr>
                <td><strong>Total:</strong></td>
                <td><strong>${total.toFixed(2)}</strong></td>
              </tr>
            </tbody>
          </table>

          <button className="checkout-button" onClick={handleStripeCheckout}>
            Checkout
          </button>
        </div>
      )}
    </div>
  );
}

Cart.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  setCartItems: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      imageUrls: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string,
    })
  ).isRequired,
};

Cart.defaultProps = {
  products: [],
};

export default Cart;
