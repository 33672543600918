import React, { useEffect, useState } from "react";
import axios from "axios";
import { PlusCircle, Trash } from "lucide-react";
import "./AdminPage.css"; // Import the dedicated Admin Page CSS

const ADMIN_PASSWORD = "123456789"; // Change this to a strong password

const AdminPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    description: "",
    imageUrls: [],
  });
  const [imagePreview, setImagePreview] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      fetchProducts();
    }
  }, [isAuthenticated]);

  const fetchProducts = async () => {
    try {
      const res = await axios.get("https://api.nebstudios.com/api/admin/products");
      setProducts(res.data);
    } catch (error) {
      console.error("❌ Error fetching products:", error);
    }
  };

  const handleChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
  };

  const addImageUrl = () => {
    if (imageUrl.trim() !== "") {
      setNewProduct({ ...newProduct, imageUrls: [...newProduct.imageUrls, imageUrl] });
      setImagePreview([...imagePreview, imageUrl]); 
      setImageUrl(""); 
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...newProduct.imageUrls];
    updatedImages.splice(index, 1);
    setNewProduct({ ...newProduct, imageUrls: updatedImages });

    const updatedPreview = [...imagePreview];
    updatedPreview.splice(index, 1);
    setImagePreview(updatedPreview);
  };

  const handleAddProduct = async () => {
    if (!newProduct.name || !newProduct.price || !newProduct.description || newProduct.imageUrls.length === 0) {
      alert("⚠️ Please fill all fields and add at least one image.");
      return;
    }
    try {
      await axios.post("https://api.nebstudios.com/api/admin/products/add", newProduct);
      fetchProducts();
      setNewProduct({ name: "", price: "", description: "", imageUrls: [] });
      setImagePreview([]);
    } catch (error) {
      console.error("❌ Error adding product:", error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await axios.delete(`https://api.nebstudios.com/api/admin/products/delete/${id}`);
      fetchProducts();
    } catch (error) {
      console.error("❌ Error deleting product:", error);
    }
  };

  const handleLogin = () => {
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert("❌ Incorrect Password! Try again.");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="password-container">
        <h2>Admin Access Required</h2>
        <input
          type="password"
          placeholder="Enter Admin Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  return (
    <div className="admin-container">
      <h1>Admin Panel</h1>

      <div className="add-product">
        <h3>Add Product</h3>
        <input
          type="text"
          name="name"
          placeholder="Product Name"
          value={newProduct.name}
          onChange={handleChange}
        />
        <input
          type="number"
          name="price"
          placeholder="Price"
          value={newProduct.price}
          onChange={handleChange}
        />
        <textarea
          name="description"
          placeholder="Description"
          value={newProduct.description}
          onChange={handleChange}
        />

        <div className="image-upload">
          <input
            type="text"
            placeholder="Enter Image URL"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
          <button onClick={addImageUrl}>
            <PlusCircle size={20} /> Add Image
          </button>
        </div>

        <div className="image-preview">
          {imagePreview.map((img, index) => (
            <div key={index} className="admin-image-container">
              <img
                src={img}
                alt={`Preview ${index}`}
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/100"; 
                }}
              />
              <button className="remove-image" onClick={() => removeImage(index)}>
                <Trash size={18} />
              </button>
            </div>
          ))}
        </div>

        <button className="add-product-btn" onClick={handleAddProduct}>
          Add Product
        </button>
      </div>

            <div className="product-list">
        <h3>Product List</h3>
        {products.map((product) => (
          <div key={product._id} className="product-item">
            <div className="admin-image-container">
              <img
                src={product.imageUrls[0]}
                alt={product.name}
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/100"; // Fallback image if the URL is invalid
                }}
                loading="lazy"
              />
            </div>
            <div className="product-details">
              <h4>{product.name}</h4>
              <p>${product.price}</p>
              <p>{product.description}</p>
            </div>
            <button className="delete-btn" onClick={() => handleDeleteProduct(product._id)}>
              <Trash size={20} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPage;
