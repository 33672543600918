// frontend/src/components/ThankYou.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./ThankYou.css";

function ThankYou({ cartItems, setCartItems, products }) {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState("");
  const location = useLocation();

  // Helper: Look up product details by name from products prop
  const getProductDetails = (name) => {
    if (!products || !Array.isArray(products)) {
      console.error("Products data is undefined or not an array.");
      return null;
    }
    return products.find((product) => product.name === name);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");

    if (!sessionId) {
      setError("No session_id found in URL.");
      return;
    }

    axios
      .get(`https://api.nebstudios.com/api/orders/by-session/${sessionId}`)
      .then((res) => {
        setOrder(res.data);
        // Clear the cart so purchased items do not remain
        setCartItems([]);
        localStorage.removeItem("cartItems");
      })
      .catch((err) => {
        console.error("Error fetching order:", err);
        setError("Could not find order details.");
      });
  }, [location.search, setCartItems]);

  if (error) {
    return <div className="thank-you-page error">{error}</div>;
  }

  if (!order) {
    return <div className="thank-you-page">Loading your order...</div>;
  }

  // Destructure data from the order
  const {
    _id: orderNumber,
    userName,
    userEmail,
    phoneNumber,
    shippingAddress,
    products: orderProducts,
    subtotal,
    total,
    shippingCost,
    createdAt,
  } = order;

  // Build address for map
  const addressString = shippingAddress
    ? `${shippingAddress.line1}, ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postal_code}, ${shippingAddress.country}`
    : "";

  // Format date
  const confirmedDate = createdAt
    ? new Date(createdAt).toLocaleDateString()
    : "N/A";

  // Convert amounts from cents to dollars
  const subtotalDollars = ((subtotal || 0) / 100).toFixed(2);
  const totalDollars = ((total || 0) / 100).toFixed(2);
  const shippingDollars = shippingCost
    ? ((shippingCost || 0) / 100).toFixed(2)
    : null;

  return (
    <div className="thank-you-page">
      {/* Top Title Section */}
      <div className="top-section">
        <div className="order-header">
          <div className="order-info-text">
            <p className="order-number">Order #{orderNumber}</p>
            <h3 className="thank-you-text">Thank you {userName}!</h3>
            <p className="order-updates">
              You will receive order and shipping updates via email:{" "}
              <strong>{userEmail}</strong>
            </p>
          </div>
        </div>
      </div>

      {/* Main Content: Two columns on desktop */}
      <div className="main-content">
        {/* LEFT COLUMN */}
        <div className="left-column">
          {/* MAP */}
          {shippingAddress && (
            <div className="map-container">
              <iframe
                title="Shipping Address Map"
                src={`https://www.google.com/maps?q=${encodeURIComponent(
                  addressString
                )}&output=embed`}
                loading="lazy"
                allowFullScreen
              ></iframe>
            </div>
          )}

          {/* CONTACT & SHIPPING INFO */}
          <div className="contact-shipping">
            <p className="contact-label">Contact Method</p>
            <p className="contact-value">{userEmail}</p>
            <p className="contact-value">{phoneNumber}</p>
            <p className="address-label">Shipping Address</p>
            {shippingAddress ? (
              <p className="address-value">
                {shippingAddress.line1}
                {shippingAddress.line2 && <br />}
                {shippingAddress.line2}
                <br />
                {shippingAddress.city}, {shippingAddress.state}{" "}
                {shippingAddress.postal_code}
                <br />
                {shippingAddress.country}
              </p>
            ) : (
              <p>No shipping address available.</p>
            )}
            <p className="confirmed-date">Confirmed Date: {confirmedDate}</p>
          </div>
        </div>

        {/* RIGHT COLUMN */}
        <div className="right-column">
          <h4 className="section-title">Your Order</h4>
          {orderProducts?.map((item, idx) => {
            // Use getProductDetails to fetch product image from products prop
            const productDetails = getProductDetails(item.name);
            const imageUrl =
              (productDetails &&
                productDetails.imageUrls &&
                productDetails.imageUrls[0]) ||
              "";
            return (
              <div className="order-item" key={idx}>
                <img
                  src={imageUrl || "https://via.placeholder.com/60"}
                  alt={item.name}
                  className="item-image"
                  onError={(e) => {
                    console.error("Image failed to load", e);
                  }}
                />
                <div className="item-details">
                  <p className="item-name">{item.name}</p>
                </div>
                <p className="item-price">
                  ${((item.amountSubtotal || 0) / 100).toFixed(2)}
                </p>
              </div>
            );
          })}

          {/* Summary */}
          <div className="summary">
            <div className="summary-line">
              <span>Subtotal</span>
              <span>${subtotalDollars}</span>
            </div>
            {shippingDollars && (
              <div className="summary-line">
                <span>Shipping</span>
                <span>${shippingDollars}</span>
              </div>
            )}
            <div className="summary-line">
              <span>Tax</span>
              <span>$0.00</span>
            </div>
            <div className="summary-line total-line">
              <span>Total</span>
              <span>${totalDollars}</span>
            </div>
          </div>

          {/* Continue Shopping Button */}
          <button
            className="continue-shopping-button"
            onClick={() => (window.location.href = "/")}
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
