import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import logo from "./assets/NebLogo.png";
import cartIcon from "./assets/cart.png";
import Cart from "./components/Cart";
import ThankYou from "./components/Thankyou";
import AdminPage from "./components/AdminPage";

// Custom hook to detect media query
function useMediaQuery(query) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (e) => setMatches(e.matches);
    mediaQueryList.addListener(listener);
    return () => mediaQueryList.removeListener(listener);
  }, [query]);
  return matches;
}

const Header = ({ cartItems }) => {
  const location = useLocation();
  return (
    <header className="header">
      <Link to="/" aria-label="Home">
        <img src={logo} alt="Brand Logo" className="logo" />
      </Link>
      {location.pathname !== "/thank-you" && cartItems.length > 0 && (
        <div className="cart-container">
          <Link to="/cart" className="cart-button" aria-label="View Cart">
            <img src={cartIcon} alt="Cart Icon" className="cart-icon" />
            <span className="cart-count">
              {cartItems.reduce((sum, item) => sum + item.quantity, 0)}
            </span>
          </Link>
        </div>
      )}
    </header>
  );
};

const AppContent = () => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem("cartItems");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState("M");
  const [isLoading, setIsLoading] = useState(true);

  // Detect if viewport is mobile (max-width: 768px)
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get("https://api.nebstudios.com/api/products");
        setProducts(res.data);
        setIsLoading(false);
      } catch (error) {
        console.error("❌ Error fetching products:", error);
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);

  // Updated addToCart accepts full product object
  const addToCart = (product, size) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item._id === product._id && item.size === size
      );
      if (existingItem) {
        return prevItems.map((item) =>
          item._id === product._id && item.size === size
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [
          ...prevItems,
          {
            _id: product._id,
            name: product.name,
            size,
            quantity: 1,
            imageUrls: product.imageUrls, // include imageUrls
            unitPrice: product.price,
          },
        ];
      }
    });
  };

  const openProductModal = (product) => setSelectedProduct(product);
  const closeProductModal = () => {
    setSelectedProduct(null);
    setSelectedSize("M");
  };
  const handleSizeChange = (e) => setSelectedSize(e.target.value);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading products...</p>
      </div>
    );
  }

  return (
    <div className="app">
      <Header cartItems={cartItems} />
      <main className="main">
        <Routes>
          <Route
            path="/"
            element={
              <div className="products-container">
                {products.map((product) => (
                  <div
                    key={product._id}
                    className="product-card"
                    onClick={() => openProductModal(product)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" || e.key === " ") openProductModal(product);
                    }}
                  >
                    {product.imageUrls && product.imageUrls.length > 0 ? (
                      <img
                        src={product.imageUrls[0]}
                        alt={product.name}
                        className="product-image"
                        onError={(e) => {
                          e.target.src = "https://via.placeholder.com/150";
                        }}
                      />
                    ) : (
                      <img
                        src="https://via.placeholder.com/150"
                        alt="No content available"
                        className="product-image"
                      />
                    )}
                  </div>
                ))}
              </div>
            }
          />
          <Route
            path="/cart"
            element={
              <Cart cartItems={cartItems} setCartItems={setCartItems} products={products} />
            }
          />
          <Route
            path="/thank-you"
            element={
              <ThankYou
                cartItems={cartItems}
                setCartItems={setCartItems}
                products={products}
              />
            }
          />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </main>

      {selectedProduct && (
        <div className="modal-overlay" onClick={closeProductModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {/* Mobile transparent header added only in modal */}
            {isMobile && <div className="modal-mobile-header"></div>}
            <button className="close-button" onClick={closeProductModal} aria-label="Close Modal">
              ×
            </button>
            <div className="modal-body">
              <div className="modal-left">
                {selectedProduct.imageUrls && selectedProduct.imageUrls.length > 0 && (
                  <img
                    src={selectedProduct.imageUrls[0]}
                    alt={selectedProduct.name}
                    className="modal-image"
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/600";
                    }}
                  />
                )}
              </div>
              <div className="modal-right">
                <h2>{selectedProduct.name}</h2>
                <p>Price: ${parseFloat(selectedProduct.price).toFixed(2)}</p>
                <p>{selectedProduct.description}</p>
                <label htmlFor="size-select">
                  Size:
                  <select id="size-select" value={selectedSize} onChange={handleSizeChange}>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                  </select>
                </label>
                {/* Inline button only on desktop */}
                {!isMobile && (
                  <button
                    className="add-to-cart-button"
                    onClick={() => {
                      addToCart(selectedProduct, selectedSize);
                      closeProductModal();
                    }}
                  >
                    Add to Cart
                  </button>
                  
                )}
              </div>
            </div>
            {/* Mobile transparent footer added only in modal */}
            {isMobile && <div className="modal-mobile-footer"></div>}
            {/* Fixed button container only on mobile */}
            {isMobile && (
              <div className="modal-button-container">
                <button
                  className="add-to-cart-button"
                  onClick={() => {
                    addToCart(selectedProduct, selectedSize);
                    closeProductModal();
                  }}
                >
                  Add to Cart
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
